<template>
    <div>
      <br/>
      <iframe frameborder="0" scrolling="auto" id="bi_iframe" :srcdoc="htmlValue" style="width: 100%;"></iframe>
    </div>
</template>
<script>

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      htmlValue: Array.isArray(this.value) ? this.value.join('') : this.value,
    };
  },
  created() {
  },
  mounted() {
    console.log('mounted')
    this.adjustIframe()
  },
  watch: {
    value: {
      handler(newVal) {
        this.htmlValue = Array.isArray(newVal) ? newVal.join('') : newVal;
      },
      immediate: true,
    },
  },
  methods: {
    adjustIframe() {
        var iframe = document.getElementById('bi_iframe');
        console.log(iframe)
    iframe.onload = function() {
        // 计算iframe内容的实际高度
        var height = iframe.contentWindow.document.body.scrollHeight;
        console.log(height)

        // 设置iframe的高度
        iframe.style.height = (height + 10) + 'px';
        iframe.style.width = '100%'
    };
    }
  }
};
</script>
