<template>
  <div class="formily-todo">
    <FormProvider :form="form">
      <FormLayout :labelCol="0" :wrapperCol="0" :labelWidth="120">
        <SchemaField :schema="formSchema || {}" />
      </FormLayout>
    </FormProvider>
  </div>
</template>

<script>
import { createForm } from '@formily/core';
import Space from './Space';
import Input from './Input';
import Upload from './Upload';
import Picker from './Picker';
import TimePicker from './TimePicker';
import Quill from '@/components/Formily/quill';
import Text from './text';
import HtmlText from './htmlText.ts';
import Link from './link';
import Images from './images.ts';
import Button from './button';
import FormTab from './formTab';
import Scanner from './scanner.js';
import Export from './Export.vue';
import Import from './import.ts';
import Location from './location.ts';
import Contact from './contact.ts';
import FormCollapse from './FormCollapse.js';
import Signature from './signature.ts';
import ArrayCards from './card.js';
import BackTop from './backTop.js';
import TimeButton from './timeButton.ts';
import ToStorageTable from '@/components/Formily/toStorageTable.ts';
import OutboundMeasurementTable from '@/components/Formily/OutboundMeasurementTable.ts';
import OutgoingPieceworkTable from '@/components/Formily/OutgoingPieceworkTable.ts';
import undercarriageGoBackTable from '@/components/Formily/undercarriageGoBackTable.ts';
import Template from '@/components/Formily/FormilyTemplate.ts';
import { isEqual } from 'lodash';
import NetworkAssetRemovalTable from '@/components/Formily/NetworkAssetRemovalTable.ts';
import {
  formilyStepper,
  formilyCascader,
  formilyTimePicker,
  formilyFormItem,
  formilySwitch,
  formilyFormGrid,
} from './index.ts';
import CommonSelect from '@/components/Formily/CommonSelect';
import UserSelect from '@/components/Formily/userSelect';
import { FormProvider, createSchemaField } from '@formily/vue';
import AssetsStorageoxTable from '@/components/Formily/AssetsStorageoxTable.ts';
import AssetsStorckboxTable from '@/components/Formily/AssetsStorckboxTable.ts';
import ServerEntryTable from '@/components/Formily/ServerEntryTable.ts';
import ServerOutboundTable from '@/components/Formily/ServerOutboundTable.ts';
import FacilityAssetEntryTable from '@/components/Formily/FacilityAssetEntryTable.ts';
import FacilityAssetReleaseTable from '@/components/Formily/FacilityAssetReleaseTable.ts';
import Steps from './Steps.vue';
import networkOutboundTable from '@/components/Formily/networkOutboundTable.ts';
import assetLocationVerificationTable from '@/components/Formily/assetLocationVerificationTable.ts';
import serverDirectAllocationTable from '@/components/Formily/serverDirectAllocationTable.ts';
import AssetesCascader from '@/components/Formily/assetesCascader.ts';
import {
  Form,
  Group,
  Submit,
  // ArrayTable,
  // Input,
  // Editable,
  PreviewText,
  Checkbox,
  // FormGrid,
  // Select,
  // Picker,
  // ArrayItems,
  // Space,
  FormLayout,
  // Reset,
  // Cascader,
  Radio,
  // Password,
  // TimePicker,
  // DatetimePicker,
} from '@formily/vant';
import { ArrayTable } from '@formily/element';
// import Breadcrumb from '@/components/Breadcrumb';
import { Message, Dialog, MessageBox } from 'element-ui';
import { getDataByNbrokerUrl } from '@/api/tickets/ticketsApi.js'
import dayjs from 'dayjs'
import store from '@/store';

const fields = createSchemaField({
  components: {
    Form,
    Submit,
    FormItem: formilyFormItem,
    ArrayTable,
    ArrayCards,
    Input,
    // Editable,
    PreviewText,
    Checkbox,
    FormGrid: formilyFormGrid,
    Select: Picker,
    // ArrayItems,
    Space,
    FormLayout,
    // Reset,
    // Text,
    // Link,
    // Button,
    Radio,
    // Password,
    TimePicker,
    DatePicker: formilyTimePicker,
    TextArea: Input,
    Switch: formilySwitch,
    Password: Input,
    FormCollapse,
    Location,
    // FormTab,
    // ArrayCards,
    Template,
    Upload,
    Cascader: formilyCascader,
    Quill,
    Text,
    Link,
    FormTab,
    Button,
    NumberPicker: formilyStepper,
    UserSelect,
    CommonSelect,
    Images,
    Scanner,
    Signature,
    Export,
    Import,
    Contact,
    BackTop,
    TimeButton,
    ToStorageTable,
    OutboundMeasurementTable,
    OutgoingPieceworkTable,
    undercarriageGoBackTable,
    // ArrayCollapse,
    // Quill,
    // UserSelect,
    // CommonSelect,
    HtmlText,
    AssetsStorageoxTable,
    AssetsStorckboxTable,
    ServerEntryTable,
    ServerOutboundTable,
    NetworkAssetRemovalTable,
    FacilityAssetReleaseTable,
    FacilityAssetEntryTable,
    Steps,
    assetLocationVerificationTable,
    serverDirectAllocationTable,
    networkOutboundTable,
    AssetesCascader,
  },
  scope: {
    ding: document.querySelector('#ding-mp3'),
    alarm: document.querySelector('#alarm-mp3'),
    finish: document.querySelector('#finish-mp3'),
    currentUser: sessionStorage.getItem('userid') || '',
    Message,
    Dialog,
    MessageBox,
    getDataByNbrokerUrl,
    dayjs,
    rainbowData: store.state.config,
  },
});

export default {
  components: { FormLayout, FormProvider, ...fields },
  props: ['schema', 'values', 'disabled'],
  data() {
    const form = createForm();
    const formSchema = this.formatSchema(this.schema);
    // const formCollapse = FormCollapse.createFormCollapse()
    return {
      // qqq,
      form,
      formSchema,
      // formCollapse,
    };
  },
  watch: {
    schema(newVal) {
      this.formSchema = this.formatSchema(newVal);
    },
    values: {
      handler(newVal, oldVal) {
        if (isEqual(newVal, oldVal)) return;
        const val = this.formatValue(newVal);
        this.form.setValues(val, 'deepMerge');
      },
      deep: true
    },
  },
  mounted() {
    const val = this.formatValue(this.values);
    this.form.setValues(val);
  },
  methods: {
    formatValue(val) {
      const res = JSON.stringify(val, (key, value) => {
        if (Array.isArray(value)) {
          return value.map((item) => (item === null ? {} : item));
        }
        return value;
      });
      return JSON.parse(res);
    },
    recursiveDelete(obj) {
      if (!obj) return;
      if (obj.properties) {
        this.recursiveDelete(obj.properties);
      }
      Object.values(obj).map((property) => {
        if (property.properties) {
          this.recursiveDelete(property.properties);
        }
        if (property.items) {
          this.recursiveDelete(property.items);
        }
        if (
          [
            'DatePicker',
            'Select',
            'TimePicker',
            'Input',
            'Input.TextArea',
            'Cascader',
            'Space',
            'FormGrid',
          ].includes(property['x-component'])
        ) {
          const decorator = property;
          decorator['x-decorator'] = null;
        }
        if (!property.title && ['ArrayTable', 'ArrayCard', 'ToStorageTable','OutboundMeasurementTable','OutgoingPieceworkTable','undercarriageGoBackTable', 'AssetsStorageoxTable', 'AssetsStorckboxTable','ServerEntryTable','ServerOutboundTable',"NetworkAssetRemovalTable",'FacilityAssetReleaseTable','FacilityAssetEntryTable','serverDirectAllocationTable','assetLocationVerificationTable','networkOutboundTable','AssetesCascader'].includes(property['x-component'])) {
          const decorator = property;
          decorator['x-decorator'] = null;
        }
        if (['Input.TextArea'].includes(property['x-component']) && property['x-pattern'] === 'readPretty' && !property?.title) {
          property['x-component'] = 'Text'
        }
        if (property['x-component'] === 'ArrayTable.Column' && Object.values(obj).length > 2) {
          const decorator = property;
          decorator['x-component-props'] = {
            ...decorator['x-component-props'],
            width: decorator['x-component-props'].width || 150,
          };
        }
      });
    },
    recursiveDisable(obj) {
      if (!obj) return;
      if (obj.properties) {
        this.recursiveDisable(obj.properties);
      }
      Object.values(obj).map((property) => {
        if (property.properties) {
          this.recursiveDisable(property.properties);
        }
        if (property.items) {
          const item = property;
          if (!['Export'].includes(item['component'])) {
            item['x-pattern'] = 'disabled';
            this.recursiveDisable(property.items);
          }
        }
        if (property['x-component']) {
          const item = property;
          if (!['Export'].includes(item['component'])) {
            item['x-pattern'] = 'disabled';
          }
        }
      });
    },
    formatSchema(val) {
      try {
        this.recursiveDelete(val.schema ? val.schema : val);
        this.disabled && this.recursiveDisable(val.schema ? val.schema : val);
        const str = JSON.stringify(val, this.formatJson).replace(
          /\/relay\/api/g,
          '/relay/ifob/api'
        );
        const res = JSON.parse(str);
        console.log(res);
        if (res && res.schema) {
          return res.schema;
        }
        return res || {};
      } catch (e) {
        console.log(e);
        return {};
      }
    },
    formatJson(key, value) {
      if (key === 'labelWidth') return undefined;
      if (key === 'x-component' && value === 'Input.TextArea') return 'TextArea';
      if (key === 'enum' && Array.isArray(value))
        return value.map((item) =>
          typeof item === 'object' ? { ...item, name: item.name || item.value } : item
        );
      return value;
    },
    getValues() {
      return this.form.values;
    },
    async validate() {
      return await this.form.validate();
    },
    setValues(newVal) {
      const val = this.formatValue(newVal);
      this.form.setValues(val, 'deepMerge');
    },
    reset() {
      Object.values(this.form.fields).forEach(item => item.reset && item.reset());
    },
  },
};
</script>

<style scoped lang="less">
.formily-todo {
  /deep/ .el-pagination{
    overflow: auto;
    margin: 0 18% 0 5%;
  }
  /deep/ .ql-picker-label {
    display: inline-flex;
    align-items: center;
  }
  /deep/ .ql-picker-options {
    z-index: 999;
  }
  /deep/ .ql-picker {
    display: inline-flex;
    align-items: center;
  }
  /deep/ .van-cell__value.van-cell__value--alone.van-field__value {
    overflow: auto;
  }
  /deep/ .formily-vant-preview-text::after,
  /deep/ .van-cell::after {
    display: block;
  }
  /deep/ .van-cell__value .van-radio-group,
  /deep/ .van-cell__value .van-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  /deep/ .van-field__label .van-cell {
    padding: 0;
    overflow: inherit;
    white-space: pre-line;
  }
  /deep/ .van-stepper__input {
    width: auto;
  }
  /deep/ .formily-element-array-table,
  /deep/ .formily-element-array-cards {
    width: 100%;
  }
  /deep/ .formily-vant-preview-input-title {
    width: auto;
  }
  /deep/ .van-field__label .van-cell::after {
    border: none;
  }
  /deep/ .van-field--disabled {
    background: #f7f8fa;
    transition: background 0.2s ease-out;
  }
  /deep/ .van-field {
    transition: background 0.2s ease-out;
  }
  /deep/ .van-cell__value.van-field__value:has(.formily-element-array-table){
    overflow: inherit
  }
}
</style>
<style lang="less">
.van-picker-column__item--selected {
  /* 重写选中后的效果 */
  .van-ellipsis {
    text-overflow: unset;
    overflow: visible;
    white-space: unset;
  }
}
    .el-message-box{
      width: 300px !important;
    }
    .el-message {
      min-width: 300px !important;
    }
</style>
